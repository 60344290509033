@use "../../style_vars.scss" as *;
.map {
    position: absolute;
    display: flex;
    justify-content: space-around;
    left: 5%;
    top: 20%;
    width: 90%;
    max-height: 55vh;
    aspect-ratio: 1;
    background-color: $main-bg-color;
    border-radius: 1em;
    padding: 1.5%;
}

@media (orientation: landscape) {
    .map {
        height: 66.67%;
        max-height: none;
        max-width: 60%;
    }
}
