.location {
    display: flex;
    flex-direction: column;
    max-width: 5%;
    width: 5%;
}

.flames {
    display: flex;
    flex-direction: row;
    max-height: 30vh;
    width: 100%;
}

.flame-img {
    height: auto;
}
