@use "../../style_vars.scss" as *;

.fg-button-container {
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 90%;
    height: fit-content;
    background-color: $main-bg-color-80-alpha;
    border-radius: 1em;
    padding: 1.5%;
}

@media (orientation: landscape) {
    .fg-button-container {
        position: absolute;
        right: 5%;
        top: 20%;
        height: 66.67%;
        width: 20%;
        background-color: $main-bg-color-80-alpha;
    }
}
