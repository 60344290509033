@use "../../style_vars.scss" as *;

.status-panel {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 5%;
    left: 20%;
    width: 60%;
    height: fit-content;
    background-color: $main-bg-color;
    border-radius: 1em;
    padding: 0.5em;
}

@media (orientation: landscape) {
    .status-panel {
        left: 20%;
        top: 3%;
    }
}
